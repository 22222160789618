<template>
  <div class="login-box">
    <section id="firebaseui-auth-container"></section>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { EmailAuthProvider, GoogleAuthProvider } from '@firebase/auth';

export default class LoginBox extends Vue {
  mounted() {
    const ui = this.$authUi;

    const uiConfig = {
      signInSuccessUrl: '/profile',
      signInOptions: [
        EmailAuthProvider.PROVIDER_ID,
        GoogleAuthProvider.PROVIDER_ID,
      ],
    };
    ui.start('#firebaseui-auth-container', uiConfig);
  }
}
</script>
