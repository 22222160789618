
import { Options, Vue } from 'vue-class-component';
import LoginBox from '@/components/LoginBox.vue';

@Options({
  components: {
    LoginBox,
  },
})
export default class Login extends Vue {}
