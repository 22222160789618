<template>
  <div class="login">
    <h1>Login to Launchpad</h1>
    <LoginBox />
  </div>
</template>

<style lang="stylus">

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import LoginBox from '@/components/LoginBox.vue';

@Options({
  components: {
    LoginBox,
  },
})
export default class Login extends Vue {}
</script>
